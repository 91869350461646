// Put bootstrap class overwrites here
body {
  background-color: #F7F7FC;
}

.selectable {
  cursor: pointer;
}

.card {
  border: 1px solid $gray-400;
  border-radius: 0;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.nav-tabs {
  .nav-item {
    .nav-link {
      color: $primary;
      background-color: white;
      border: 1px solid $gray-400;
      border-bottom: none;
    }
    .nav-link.active {
      color: white;
      background-color: $primary;
    }
  }
}

.upper-modal {
  z-index: 1060
}

.upper-modal-backdrop {
  z-index: 1051;
}

.ql-editor {
  min-height: 100px;
}

.hide-caret.dropdown-toggle::after {
  display: none;
}


.top-nav-item.dropdown-item {
  color: white;
  &:hover {
    background-color: lightgray;
    color: black;
  }
}

.xxl-modal {
  .modal-dialog {
    max-width: 95%;
  }
}

.btn {
  border-radius: 4px;
}

.table {
  th {
    background-color: #fafafc;
    color: $gray-700;
  }

  tr {
    &:hover {
      background-color: #ebebee;
    }

    th, td {
      vertical-align: middle;
    }
  }
}

.extend-table-container {
  .table-responsive {
    padding-bottom: 88px;
  }
}

.font-weight-semi-bold {
  font-weight: 500;
}

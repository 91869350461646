$font-size-base: 1rem;
$grid-gutter-width: 32px;
$small-font-size: 12px;
$lead-font-size: 16px;
$h5-font-size: $font-size-base * 1.5;
$h4-font-size: $font-size-base * 2;
$h3-font-size: $font-size-base * 2.5;
$h2-font-size: $font-size-base * 3;
$h1-font-size: $font-size-base * 4;
$headings-line-height: 1.25;
$btn-border-radius: 24px;
$btn-border-radius-lg: 24px;
$btn-border-radius-sm: 24px;
$card-border-radius: 16px;
$input-border-radius: 0px;
// $font-family-sans-serif: avenir;
// $font-family-monospace: avenir;
$enable-responsive-font-sizes: true;

// $grid-breakpoints: (
//   xs: 0,
//   sm: 576px,
//   md: 768px,
//   lg: 992px,
//   xl: 1200px,
//   xxl: 1400px,
//   xxxl: 1600px,
//   ) !default;

// $container-max-widths: (
//   sm: 540px,
//   md: 720px,
//   lg: 960px,
//   xl: 1280px) !default;
.search-input {
  display: flex;
  align-items: center;

  .fa-search {
    position: absolute;
    right: 24px;
  }

  input {
    padding: 16px 24px 16px 16px;
  }
}

$spacer: 1rem !default;
$spacers: (
  ) !default;
$spacers: map-merge((0: 0,
      8: ($spacer * .5,
      ),
      16: ($spacer,
      ),
      24: ($spacer * 1.5,
      ),
      32: ($spacer * 2,
      ),
      40: ($spacer * 2.5,
      ),
      48: ($spacer * 3,
      ),
      56: ($spacer * 3.5,
      ),
      64: ($spacer * 4,
      ),
      72: ($spacer * 4.5,
      ),
      80: ($spacer * 5,
      ),
      88: ($spacer * 5.5,
      ),
      96: ($spacer * 6,
      ),
      120: ($spacer * 7.5,
      ),
      128: ($spacer * 8,
      ),
      136: ($spacer * 8.5,
      ),
      144: ($spacer * 9,
      ),
      152: ($spacer * 9.5,
      ),
      168: ($spacer * 10.5,
      ),
    ),
    $spacers
);

.banner {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    margin-bottom: 0px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;

    &-auth {
        background: url('/assets/images/green_suburb.jpeg') no-repeat center center fixed;
    }

    &-app {
        background: url('/assets/images/cloud.jpg') no-repeat center center fixed;
    }
}
#notification-drawer {
  position: fixed;
  top: 96px;
  right: 0;
  min-width: 200px;
  min-height: 20%;
  border-radius: 16px 0 0 16px;
  max-height: calc(100vh - 104px);
  opacity: 0;
  transform: translateX(100%);
  transition: 0.5s ease;
}

#notification-drawer.is-open {
  opacity: 1;
  transform: translateX(0);
}

.notification-drawer-body {
  overflow-y: auto;
  overflow-x: hidden;
}

// $primary: #41696C;
$primary: rgb(41, 100, 155);
// $primary-hover: #609599;
// $primary-active: #92DEE0;
// $primary-100: #EFF4F5;

// $secondary: #BC7057;
// $secondary-900: #E7C0AA;
// $secondary-500: #FBE5DB;
// $secondary-300: #FCEDE6;
// $secondary-200: #FEF7F4;

 //$info: #FFD292;
 //$info-active: #FFE8C8;
 //$info-hover: #FFF4E4;

 $danger: #b32d2d;
 $danger-active: #FFE6E4;
 $danger-hover: #F69C97;

 $warning: #d1aa34;
 $warning-active: #FFD27A;
 $warning-hover: #FFF2CF;

 $success: #3f8452;
 $success-active: #DDF1E5;
 $success-hover: #74DD9E;

// $dark: #161616;
// $dark-900: #505050;
// $dark-500: #8A8A8A;

// $gray: #EDEDED;
// $gray-300: #F2F2F2;
// $gray-200: #F6F6F6;
// $gray-100: #FAFAFA;

$orange: #D57921;

$transparent: transparent;
$white: white;
$black: black;

$theme-colors: (
//   "primary":$primary,
//   "primary-hover":$primary-hover,
//   "primary-active":$primary-active,
//   "primary-100":$primary-100,
//   "secondary":$secondary,
//   "secondary-900":$secondary-900,
//   "secondary-500":$secondary-500,
//   "secondary-300":$secondary-300,
//   "secondary-200":$secondary-200,
//   "info":$info,
//   "info-active":$info-active,
//   "info-hover":$info-hover,
//   "danger":$danger,
//   "danger-active":$danger-active,
//   "danger-hover":$danger-hover,
//   "warning":$warning,
//   "warning-active":$warning-active,
//   "warning-hover":$warning-hover,
//   "success":$success,
//   "success-active":$success-active,
//   "success-hover":$success-hover,
//   "dark":$dark,
//   "dark-900":$dark-900,
//   "dark-500":$dark-500,
//   "gray":$gray,
//   "gray-300":$gray-300,
//   "gray-200":$gray-200,
//   "gray-100":$gray-100,
//   "transparent":$transparent,
//   "black":$black,
//   "white":$white,
  "orange": $orange,
);

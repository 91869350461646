@each $breakpoint in map-keys($grid-breakpoints) {
  @each $size, $length in $sizes {
    @include media-breakpoint-up($breakpoint) {
      .w-#{$breakpoint}-#{$size} {width: $length !important;}
    }
  }
}

$fontWeights: (100,200,300,400,500,600,700,800);
@each $weight in $fontWeights {
  .font-#{$weight} {
    font-weight: $weight
  }
}

.pointer {
  cursor: pointer;
}

.application-detail-documents {
  th {
    width: 25%;
  }
}

.text-overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}

.sticky-column {
  position: sticky;
  left: 0;
  z-index: 1;
}

.sticky-column-end {
  position: sticky;
  right: 0;
  z-index: 1;
}

.sticky-top {
  th {
    position: sticky;
    top: 0;
    z-index: 2;
  }
}

.annual-report-container {
  .table-responsive {
    max-height: 80vh;
  }
  table {
    border-collapse: separate;
    border-spacing: 0;
    td {
      border-width: 0.5px;
    }
    tbody {
      tr:first-child td {
        border-top: none;
      }
      tr:last-child td {
        border-bottom-width: 1px;
      }
    }
  }
}

.cap-table-input {
  min-width: 200px;
}

.pagination {
  .pagination-next {
    a {
      display: flex;
      flex-direction: row-reverse;
    }
  }
}

.search-button {
  border-top: solid black 1px;
  border-right: solid black 1px;
  border-bottom: solid black 1px;
}

.search-result-link {
  position: absolute;
  top: 24px;
  right: 24px;
}

.scroll-tabs {
  overflow-x: auto;
  overflow-y: hidden;
  flex-wrap: nowrap;
  display: -webkit-box;
  display: -moz-box;

  li {
    float: none;
  }
}
